import React from 'react'
import Layout from './layout'

export const Para = ({ children }) => (
  <p className="pb-2">
    {children}
  </p>
)

export const Subheading = ({ children }) => {
  return (
    <h2 className="pb-4 mt-8">
      {children}
    </h2>
  )
}

export const Article = ({ children, title, published }) => (
  <Layout>
    <div className="bg-black text-white">
      <div className="container mx-auto">
        <div className="px-4 py-10">
          <h1 className="font-light text-4xl">{title}</h1>
          {!published ? null : <p className="text-grey-light pt-4">
            {published}
          </p>}
        </div>
      </div>
    </div>

    <div className="container mx-auto pb-32">
      <div className="px-4 py-4 mb-8 leading-loose">
        {children}
      </div>
    </div>
  </Layout>
)
