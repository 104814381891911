import React from 'react'

import { Subheading, Para, Article } from '../../components/article'
import {Link} from "@reach/router";

export default () => (
  <Article title="On-premise monitoring agents private beta" published="April 13th, 2019">
    <Subheading>Monitoring internal networks</Subheading>
    <Para>
      One of the goals we've always had for <a href="https://whoops.io">
      Whoops.io</a> is to enable monitoring of internal networks as
      easily as it is to monitor external/public networks.
    </Para>
    <Para>
      Often the most vital pieces of your infrastructure live inside your private network, behind
      firewalls and network policies, preventing our cloud agents from reaching them to perform
      any kind of uptime check. We're talking about firewalls, routers, switches, database servers,
      Active Directory servers, proxy servers, etc -- just about anything which exposes something
      over TCP or HTTP.
    </Para>
    <Para>
      Starting today we can monitor these pieces of your infrastructure with launch of on-premise
      monitoring agents. These are applications which you install on a server somewhere inside your
      network, which pick up the task of performing those internal checks.
    </Para>
    <Para>
      Our new on-premise agents run the same technology which has been
      powering our cloud agents since we first launched, so they're
      well battle tested and ready for use.
    </Para>
    <Subheading>How to use</Subheading>
    <div className="text-pink-dark bg-pink-lighter border-pink-dark py-2 px-4 rounded-lg mb-2">
        <strong>Update</strong>: You can now setup and install and run on-premise agents yourself! Read our guide
        at <Link to="/article/onprem-agent">https://whoops.io/article/onprem-agent</Link>
    </div>
    <Para>
      As on-premise agents are still in private beta, you'll need to reach
      out to our support team, and we'll walk you through getting your
      agent installed and configured.
    </Para>
    <Para>
      That said, we think it's useful for you to know what you'll be getting
      in to, so here's a brief summary of the setup process:
    </Para>
    <ol>
      <li><strong>Install docker</strong>: Our agent ships as a docker image,
        so if you aren't familiar with Docker already, you'll need a server
        somewhere on your network that has <a href="https://www.docker.com/">
        Docker</a> installed and running.</li>
      <li><strong>Get your API credentials</strong>: We'll show you how to
      access your API credentials from your account, which you'll need to
      use when running the container.</li>
      <li><strong>Run the agent</strong>: This is a simple <code className="bg-grey-light text-sm p-1">docker run
      </code> command and you're away</li>
      <li><strong>Setup your checks</strong>: Log in to the Whoops.io platform
        and configure your checks as normal. Our normal cloud agents will
        automatically recognise any checks which target internal/private
        addresses; our cloud agents will skip them, but your on-premise agent(s)
        will execute the check.
      </li>
    </ol>
    <Subheading>Join the on-premise agents private beta</Subheading>
    <Para>
      Get in touch with us via <a href="mailto:hello@whoops.io">
      hello@whoops.io</a> if you'd like to start using on-premise agents
      today.
    </Para>
  </Article>
)
